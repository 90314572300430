import React from "react"

const IconService = ({className}) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <line className={className} x1="0.855" y1="14.251" x2="0.855" y2="23.251"/>
  <path className={className} d="M.855,21.751h15a3,3,0,0,0-3-3H9.105a3,3,0,0,0-3-3H.855"/>
  <line className={className} x1="6.105" y1="18.751" x2="9.105" y2="18.751"/>
  <circle className={className} cx="15.104" cy="9.751" r="2.25"/>
  <path className={className}
        d="M16.925,16.5l.159-1.113A5.946,5.946,0,0,0,19,14.284l1.946.781a1.5,1.5,0,0,0,1.858-.642l.2-.345a1.5,1.5,0,0,0-.373-1.93l-1.633-1.282a5.611,5.611,0,0,0,0-2.231l1.633-1.282A1.5,1.5,0,0,0,23,5.423l-.2-.345a1.5,1.5,0,0,0-1.858-.642L19,5.217a5.946,5.946,0,0,0-1.911-1.1l-.3-2.074A1.5,1.5,0,0,0,15.3.751h-.4A1.5,1.5,0,0,0,13.42,2.038l-.3,2.074a5.941,5.941,0,0,0-1.91,1.1L9.267,4.436a1.5,1.5,0,0,0-1.857.642l-.2.345a1.5,1.5,0,0,0,.372,1.93L9.217,8.635a5.562,5.562,0,0,0,0,2.231L7.583,12.148"/>
</svg>

export default IconService