import React from "react"
import PropType from "prop-types"
import Header from "../header/header"
import Footer from "../footer/footer"
import TabBar from "../navigation/tabbar"

export default class MainLayout extends React.Component {
  constructor(props) {
    super(props);
   }

  render =() => {
    const { theme, title, description, children } = this.props;
    return <React.Fragment>
  
      <Header description={description} title={title} theme={theme} />
  
      <TabBar/>
  
      <div>
        {children}
      </div>
  
      <Footer />
    </React.Fragment>
  }
}

MainLayout.defaultProps = {
  theme: "dark",
}

MainLayout.propTypes = {
  theme: PropType.string,
  title: PropType.string.isRequired,
  description: PropType.string,
  children: PropType.any.isRequired,
}
