import React from "react"

const IconBlog = ({className}) => <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" height="24" width="24">
        <g transform="matrix(1,0,0,1,0,0)">
            <path d="M 23.25,6v14.25c0,1.243-1.007,2.25-2.25,2.25s-2.25-1.007-2.25-2.25V3c0-0.828-0.672-1.5-1.5-1.5h-15 c-0.828,0-1.5,0.672-1.5,1.5v17.25c0,1.243,1.007,2.25,2.25,2.25h18 " className={className} fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M 4.5,15H15 " className={className} fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M 4.5,18h6 " className={className} fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M 5.25,5.25h9C14.664,5.25,15,5.586,15,6 v4.5c0,0.414-0.336,0.75-0.75,0.75h-9c-0.414,0-0.75-0.336-0.75-0.75V6C4.5,5.586,4.836,5.25,5.25,5.25z" className={className} fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        </g>
    </svg>

export default IconBlog