class LinkResolver {
  static resolveLink({type, document}) {
    if(document instanceof Array) {
      document = document[0];
    }

    switch(type) {
      case "landing_page":
        switch(document.uid) {
          case "index":
            return "/"
          default:
            return `/${document.uid}`;
        }
      case "blog_home":
        if (document.language === "fr-fr" ||  document.language === undefined) {
          return `/blog`;
        } else {
          let languageCode = document.language.substr(0, 2);
          return `/blog/${languageCode}`;
        }
      case "blog_p":
        if (document.language === "fr-fr" || document.language === undefined) {
          return `/blog/${document.uid}`;
        } else {
          let languageCode = document.language.substr(0, 2);
          return `/blog/${languageCode}/${document.uid}`;
        }
      case "contacts_page":
        return "/contacts"
      default:
        return null
    }
  }
}

export default LinkResolver;