import { graphql, StaticQuery, Link } from "gatsby"
import React from "react"
import PropType from "prop-types"
import NavBarLogo from "./navigationLogo"
import styles from "./bar.module.scss"
import LinkResolver from "../../link_resolver"
import Button from "../button/button"

const NavBar = ({ theme }) => (
  <div className={`row align-items-center ${styles.navBar}`}>
    <div className="col-auto">
      <Link to="/">
        <NavBarLogo fillColor={theme === "dark" ? "#FFF" : "#000"}/>
      </Link>
    </div>
    <ul className="col row align-items-center justify-content-end px-spacing-none mr-spacing-none my-spacing-none">

      <StaticQuery query={graphql`
query MainLayout {
          prismicMainLayout {
                      data {
                        body {
                          ... on PrismicMainLayoutBodyLink{
                          slice_type
                          primary {
                            link_name {
                              text
                            }
                            link {
                              type
                              
                              document {
                              ... on PrismicLandingPage {
                                uid
                              }
                                ... on PrismicBlogHome {
                                  uid
                                }
                              }
                            }
                          }
                        }
                          ... on PrismicMainLayoutBodyCta {
                          slice_type
                          primary {
                            button_text {
                              text
                            }
                            button_link {
                              type
                              
                              document {
                                uid
                              }
                            }
                          }
                          }
                        }
                      }
                    }        
          }
    `}
                   render={data => {
                     const slices = data.prismicMainLayout.data.body
                     return slices.map(slice => {
                         switch (slice.slice_type) {
                           case "link":
                             return <li className={`${styles["link"]} ${theme === "dark" ? styles["dark"] : styles["light"]} col-auto mx-spacing-16`}
                                        key={slice.primary.link.document[0].uid}>
                               <Link to={LinkResolver.resolveLink(slice.primary.link)}>
                                 {slice.primary.link_name.text}
                               </Link>
                             </li>
                           case "cta":
                             return <li className={`${styles["cta"]} col-auto ml-lg-spacing-16`} key={slice.primary.button_link.document[0].uid}>
                               <Button text={slice.primary.button_text.text} uid={slice.primary.button_link.document[0].uid} link={slice.primary.button_link}/>
                             </li>
                         }
                       },
                     )
                   }}
      >
      </StaticQuery>
    </ul>
  </div>
)

NavBar.defaultProps = {
  theme: "dark",
}

NavBar.propTypes = {
  theme: PropType.string,
}

export default NavBar
