import React from "react"
import PropType from "prop-types"

const PointingArrow = ({ fillColor }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 48 48" className="animated-vertical-arrow">
    <path fill="none" d="M0 0h48v48H0V0z"/>
    <path fill={fillColor} d="M40 24l-2.82-2.82L26 32.34V8h-4v24.34L10.84 21.16 8 24l16 16 16-16z"/>
  </svg>

)

PointingArrow.defaultProps = {
  fillColor: "#FFF'",
}

PointingArrow.propTypes = {
  fillColor: PropType.string,
}

export default PointingArrow