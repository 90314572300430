import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import defaultPoster from "./../images/social_network_poster.png"

function SEO({ description, lang, meta, keywords, title, title_template, poster }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const layout = data.prismicMainLayout.data;
        const displayedTitle = title_template ? title_template : `${title} | ${layout.open_graph_base_title}`;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={displayedTitle}
            titleTemplate={displayedTitle}
            meta={[
              {
                name: `description`,
                content: description,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: description,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: layout.seo_twitter_author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: description,
              },
              {
                property: `og:image`,
                content: poster || defaultPoster
              },
              {
                name: `twitter:image`,
                content: poster || defaultPoster
              },
              {
                property: `og:image:width`,
                content: 2000
              },
              {
                property: `og:image:height`,
                content: 1000
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                  }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  keywords: [],
  display_base_title: true
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  title_template: PropTypes.string,
  poster: PropTypes.any,
}

export default SEO

const detailsQuery = graphql`
    query SeoAttributesFromMainLayout {
        prismicMainLayout {
            data {
                open_graph_base_title
                seo_base_title
                seo_twitter_author

            }
        }
    }
`