import React from "react"
import styles from "./footer.module.scss"
import NavBar from "../navigation/bar"
import { graphql, StaticQuery } from "gatsby"

const Footer = () => (
  <div className={styles.dark}>
    <footer className="container pt-spacing-24 pt-lg-spacing-64 pb-spacing-96 pb-lg-spacing-96">
      <NavBar theme="dark"/>

      <StaticQuery query={graphql`
          query MainLayoutFooter {
            prismicMainLayout {
              data {
               copyright {
                 text
               }
                footer_description {
                  text
                }
              }
            }
          }
      `}
                   render={({ prismicMainLayout: { data: { copyright, footer_description } } }) => {
                     return <div className="row justify-content-between align-items-end">
                       <div className="col-12 col-lg-3 mt-spacing-32 mt-lg-spacing-none">
                         <p className="small">
                           {footer_description.text}
                         </p>
                       </div>
                       <div className="col-12 col-lg-auto mt-spacing-32 mt-lg-spacing-none">
                         <p className={`${styles.copyright}`}>
                           {copyright.text}
                         </p>
                       </div>
                     </div>
                   }}
      />
    </footer>
  </div>
)

export default Footer