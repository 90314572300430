import { graphql, StaticQuery, Link } from "gatsby"
import React from "react"
import styles from "./bar.module.scss"
import LinkResolver from "../../link_resolver"
import IconService from "../icons/tab_bar/services"
import IconContacts from "../icons/tab_bar/contacts"
import IconCustomer from "../icons/tab_bar/customers"
import IconHome from "../icons/tab_bar/home"
import IconBlog from "../icons/tab_bar/blog"

const TabBar = () => <div className={styles.tabBar}>

    <StaticQuery query={graphql`
        query MainLayoutTabBar {
        prismicMainLayout {
          data {
            body: body1 {
              ...on PrismicMainLayoutBody1TabItem {
                id
                primary {
                  item_text {
                    text
                  }
                  item_icon
                  item_link {
                    type 
                    document {
                      ... on PrismicLandingPage {
                        uid
                      }
                      ... on PrismicContactsPage {
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
                 render={data => {

                   const icon = (iconName) => {
                     switch (iconName) {
                       case "home":
                         return <IconHome className={styles.icon}/>
                       case "services":
                         return <IconService className={styles.icon}/>
                       case "customers":
                         return <IconCustomer className={styles.icon}/>
                       case "blog":
                         return <IconBlog className={styles.icon}/>
                       case "contacts":
                         return <IconContacts className={styles.icon}/>
                     }
                   }

                   const slices = data.prismicMainLayout.data.body
                   return slices.map(slice => {
                       return <Link to={LinkResolver.resolveLink(slice.primary.item_link)}
                                    className={styles.item}
                                    activeClassName={styles.itemActive}
                                    key={LinkResolver.resolveLink(slice.primary.item_link)}
                                    partiallyActive={LinkResolver.resolveLink(slice.primary.item_link) !== "/"}>
                            <span>
                              {icon(slice.primary.item_icon)}
                            </span>
                         <div>{slice.primary.item_text.text}</div>
                       </Link>
                     },
                   )
                 }}
    >
  </StaticQuery>
  < /div>

export default TabBar