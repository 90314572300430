import React from "react"

const IconCustomer = ({className}) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path className={className}
        d="M11.566,1.017a.486.486,0,0,1,.868,0l1.009,2.034a.483.483,0,0,0,.363.262l2.264.327a.481.481,0,0,1,.389.323.47.47,0,0,1-.121.487L14.7,6.044a.473.473,0,0,0-.138.419l.388,2.247a.474.474,0,0,1-.194.465.487.487,0,0,1-.509.035L12.226,8.156a.482.482,0,0,0-.452,0L9.758,9.21a.487.487,0,0,1-.509-.035.474.474,0,0,1-.194-.465l.388-2.247a.473.473,0,0,0-.138-.419L7.662,4.45a.47.47,0,0,1-.121-.487A.481.481,0,0,1,7.93,3.64l2.264-.327a.483.483,0,0,0,.363-.262Z"/>
  <path className={className}
        d="M6.322,10.1,5.459,9.65a.49.49,0,0,0-.453,0L2.991,10.7a.491.491,0,0,1-.51-.034.474.474,0,0,1-.193-.466l.387-2.246a.471.471,0,0,0-.137-.419L.894,5.944a.472.472,0,0,1-.12-.487.481.481,0,0,1,.389-.323l2.263-.327a.483.483,0,0,0,.364-.262L4.8,2.511a.484.484,0,0,1,.434-.267"/>
  <path className={className}
        d="M17.678,10.1l.863-.451a.49.49,0,0,1,.453,0L21.009,10.7a.491.491,0,0,0,.51-.034.474.474,0,0,0,.193-.466l-.387-2.246a.471.471,0,0,1,.137-.419l1.644-1.595a.472.472,0,0,0,.12-.487.481.481,0,0,0-.389-.323l-2.263-.327a.483.483,0,0,1-.364-.262L19.2,2.511a.484.484,0,0,0-.434-.267"/>
  <path className={className} d="M17.027,23.243a6.678,6.678,0,0,0-10.052,0"/>
  <circle className={className} cx="12" cy="15.336" r="4.125"/>
</svg>

export default IconCustomer