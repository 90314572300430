import React from "react"
import PropType from "prop-types"
import styles from "./header.module.scss"
import NavBar from "../navigation/bar"
import PointingArrow from "./pointing-arrow"

const Header = ({ theme, title, description }) => (
  <div className={styles[theme]}>
    <header className="container">
      <NavBar theme={theme} />

      <div className="mt-spacing-64 mt-md-spacing-128 cropped-content">
        <h1 className="slide-down">{title}</h1>
      </div>
      {
        description && (
          <div className="row col-12 mt-spacing-24 col-md-8 mt-md-spacing-32 cropped-content">
            <h2 className="slide-down">{description}</h2>
          </div>
        )
      }
      
      {theme === "dark" && (
        <div className="pt-spacing-48 pb-spacing-24 pt-md-spacing-96 pb-md-spacing-48">
          <PointingArrow fillColor={theme === "dark" ? "#FFF" : "#000"} />
        </div>
      )}
    </header>
  </div>
)

Header.defaultProps = {
  theme: "dark",
}

Header.propTypes = {
  theme: PropType.string,
  title: PropType.string.isRequired,
  description: PropType.string,
}

export default Header