import { Link } from "gatsby"
import React from "react"
import PropType from "prop-types"
import styles from "../button/button.module.scss"
import LinkResolver from "../../link_resolver"

const Button = ({ text, uid, directLink, link, theme }) => {
    if (directLink !== undefined) {
      return (
        <div className={`${styles["cta"]}`} key={uid}>
          <a href={directLink} className={`${styles["button"]} ${theme === "black" ? styles["blackBackground"] : styles["blueBackground"] }`}>
            {text}
          </a>
        </div>
      )
    } else if (link !== undefined) {
      return (
        <div className={`${styles["cta"]}`} key={uid}>
          <Link to={LinkResolver.resolveLink(link)} className={`${styles["button"]} ${theme === "black" ? styles["blackBackground"] : styles["blueBackground"] }`}>
            {text}
          </Link>
        </div>
      )
    }
}

Button.propTypes = {
  text: PropType.string.isRequired,
  uid: PropType.string.isRequired,
  link: PropType.any,
}

export default Button
